import ProductPageLoaderAbstract from './ProductPageLoaderAbstract';
import {data, method} from '../../../core/adapters/VueComponent';
import {Services} from '../../../core/services/Services';
import CustomService from '../services/CustomService';
import {ProofApproval} from '../models/enums';
import MessageList from '../../../core/utils/MessageList';
import RouteService from '../../../core/services/RouteService';

export default class FinalizePageApp extends ProductPageLoaderAbstract {

    @data()
    override product_service: CustomService;

    @data()
    step: number = 0;

    @data()
    action: string;

    @data()
    show_summary: boolean = false;

    @data()
    show_finishing: boolean = true;

    @data()
    finalize: boolean = false;

    @data()
    errors: MessageList;

    @data()
    disable_finishing_options: boolean;

    @data()
    warnings: MessageList;

    @data()
    infos: MessageList;

    @data()
    size_changed: boolean;

    static override init() {
        super.init(Services.get('CustomService'), 'product', 'configured_product');
    }

    override mounted() {
        super.mounted();

        this.warnings = new MessageList();
        if (Services.get<RouteService>('RouteService').current.params.get('size_changed') == '1') {
            this.warnings.add('size', 'The size has changed to match your design.');
            this.size_changed = true;
        }

        this.product_service.bind('change:price', this.$forceUpdate.bind(this));
        this.product_service.add_to_cart = true;
        this.product_service.setupRetailPackaging().then(() => {
            this.disable_finishing_options = !(this.product_service.hasPackageOptions()
                || this.product_service.base.option_laminate
                || this.product_service.base.option_reverse_cut
                || this.product_service.base.option_border
                || this.product_service.base.option_vectorize
                || this.product_service.configured.options.length > 0);

            if (this.disable_finishing_options) {
                this.finalize = true;
            }
        });

        this.product_service.setup_complete.then(() => {
            let clear_check = () => {
                if (this.product_service.configured.material.clear) {
                    this.infos = new MessageList();
                    if (this.product_service.configured.material.instructions_label) {
                        this.infos.add('instructions', this.product_service.configured.material.instructions_label);
                    } else {
                        this.infos.add('instructions', 'Please specify what you would like clear or white in the design');
                    }
                }
            }
            this.product_service.configured.bind('change:material', clear_check);
            clear_check();
        })
    }

    @method()
    save() {
        this.errors = new MessageList();
        if (this.product_service.configured.proof_approval == ProofApproval.CHANGES && !this.product_service.configured.instructions) {
            this.errors.add('instructions', 'This field is required.')
        }

        if (this.errors.length == 0) {
            this.product_service.save();
        }
    }
}