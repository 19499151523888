export const html: string = `
<template v-if="quantity_options && quantity_options.length > 1">
    <div class="option-list hide-radio">
        <label class="flex-left space-between no-wrap" v-for="tier in quantity_options">
            <input :aria-label="'Select quantity: ' + tier.quantity" type="radio" name="size" v-model="productService.quantity" :value="tier.quantity"/>
            <span>
                {{ tier.quantity }}
            </span>
            <span v-if="tier.quantity < 2">{{ $filters.currency(tier.price, productService.configured.currency) }}</span>
            <span v-if="tier.quantity > 1">{{ $filters.currency(tier.unitPrice, productService.configured.currency) }} each</span>
        </label>
    </div>
    <button class="btn-link rotate-icon" type="button" @click="show_all = !show_all; updateQuantityOptions(); checkView()" v-if="show_more">
        {{ show_all ? 'Hide price breaks' : 'View all price breaks' }}
        <svg class="icon" fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" :class="show_all ? 'flip' : ''">
            <path d="M5 7.5L10 12.5L15 7.5" stroke="var(--brand-secondary, #6941C6)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.66667"></path>
        </svg>
    </button>
</template>
`;