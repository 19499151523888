import {DisplayTypeEnum} from '../../models/enums';
import {TemplateOptionChargeOption} from '../../models/TemplateOption';

export const html:string = `

<div class="input-group" v-for="custom_option in filteredOptions(${DisplayTypeEnum.FIXED_VALUE})">
    <label>{{ custom_option.option_set.name }}</label>
    <message-list :errors="errors" field="hidden">
        <div class="flex-left-center spacing-sm">
            {{ custom_option.option.name }}
            <span v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE} && !hidePrice(custom_option)">
                ({{ custom_option.option.percent > 0 ? '+' + $filters.shortNumber(custom_option.option.percent) + '%' : '(included)' }})
            </span>
            <span v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL} && !hidePrice(custom_option)">
                ({{ custom_option.option.amount > 0 ? '+' + $filters.currency(custom_option.option.amount, productService.configured.currency) : '(included)' }})
            </span>
            <span v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT} && !hidePrice(custom_option)">
                ({{ custom_option.option.amount > 0 ? '+' + $filters.currency(custom_option.option.display_value(productService.show_retail), productService.configured.currency) + ' each' : '(included)' }})
            </span>
        </div>
    </message-list>
</div>

<div class="input-group" v-if="productService.base.option_laminate && productService.configured.material.can_laminate">
    <fieldset class="input-group single-option">
        <legend>Lamination</legend>
        <div class="toggle-group">
            <label :for="'lamination-' + item.id" v-for="item in productService.configured.laminationOptions">
                <input :id="'lamination-' + item.id" :value="item.id" name="lamination" type="radio" v-model="productService.configured.option_laminate">
                {{ item.label }}
            </label>
        </div>
    </fieldset>
</div>

<div class="input-group" v-if="reverseCut && productService.base.option_reverse_cut">
    <fieldset class="input-group single-option">
        <legend>Invert Design</legend>
        <div class="toggle-group">
            <label for="invert-no">
                <input id="invert-no" :value="false" name="invert" type="radio" v-model="productService.configured.option_reverse_cut">
                No
            </label>
            <label for="invert-yes">
                <input id="invert-yes" :value="true" name="invert" type="radio" v-model="productService.configured.option_reverse_cut">
                Yes
            </label>
        </div>
    </fieldset>
</div>

<div class="input-group" v-if="productService.base.option_border && !productService.base.offset_path_uses_material_color">

    <fieldset class="input-group single-option">
        <legend>Add Border</legend>
        <div class="toggle-group">
            <label for="border-no">
                <input id="invert-no" :value="false" name="border" type="radio" v-model="productService.configured.option_border">
                No
            </label>
            <label for="border-yes">
                <input id="invert-yes" :value="true" name="border" type="radio" v-model="productService.configured.option_border">
                Yes
            </label>
        </div>
    </fieldset>
</div>


<div class="input-group" v-for="custom_option in filteredOptions(${DisplayTypeEnum.TOGGLE_BUTTON})">
    <label>{{ custom_option.option_set.name }}</label>
    <message-list :errors="errors" field="hidden">
        <div class="flex-left-center spacing-sm">
            <input type="checkbox" class="input-toggle" :checked="optionChecked(custom_option)" v-model="custom_option.toggle_value" @change="toggleSingleOption(custom_option, custom_option.toggle_value)">
            {{ custom_option.option_set.short_description }}
            
            <span v-if="togglePriceOption(custom_option) && togglePriceOption(custom_option).charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE} && !hidePrice(custom_option)">
                ({{ togglePriceOption(custom_option).percent > 0 ? '+' + $filters.shortNumber(togglePriceOption(custom_option).percent) + '%' : '(included)' }})
            </span>
            <span v-if="togglePriceOption(custom_option) && togglePriceOption(custom_option).charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL} && !hidePrice(custom_option)">
                ({{ togglePriceOption(custom_option).amount > 0 ? '+' + $filters.currency(togglePriceOption(custom_option).amount, productService.configured.currency) : '(included)' }})
            </span>
            <span v-if="togglePriceOption(custom_option) && togglePriceOption(custom_option).charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT} && !hidePrice(custom_option)">
                ({{ togglePriceOption(custom_option).amount > 0 ? '+' + $filters.currency(togglePriceOption(custom_option).display_value(productService.show_retail), productService.configured.currency) + ' each' : '(included)' }})
            </span>
        </div>
    </message-list>
</div>

<div class="input-group" v-for="custom_option in filteredOptions(${DisplayTypeEnum.RADIO})">
    <fieldset class="input-group single-option">
        <legend>{{ custom_option.option_set.name }}</legend>
        <div class="text-xs" v-if="custom_option.option_set.short_description">{{ custom_option.option_set.short_description }}</div>
        <message-list field="option" :errors="custom_option.errors">
            <div class="toggle-group">
                <label :for="idForOptionSet(custom_option, item)" v-for="item in custom_option.option_set.options" class="flex-full-center column no-wrap spacing-none">
                    <div v-html="item.icon" v-if="item.icon"></div>
                    <input :id="idForOptionSet(custom_option, item)" :value="item.id" :name="custom_option.option_set.name" type="radio" v-model="custom_option.option_id" :disabled="!custom_option.option_set.optionEligible(productService.configured.width, productService.configured.height) || custom_option.isDisabled(productService.configured.material)">
                    {{ item.name }}
                    
                    <span class="text-sm" v-if="item.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE} && !hidePrice(custom_option)">
                        {{ item.percent > 0 ? '+' + $filters.shortNumber(item.percent) + '%' : '(included)' }}
                    </span>
                    <span class="text-sm" v-if="item.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL} && !hidePrice(custom_option)">
                        {{ item.amount > 0 ? '+' + $filters.currency(item.amount, productService.configured.currency) : '(included)' }}
                    </span>
                    <span class="text-sm" v-if="item.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT} && !hidePrice(custom_option)">
                        {{ item.amount > 0 ? '+' + $filters.currency(item.display_value(productService.show_retail), productService.configured.currency) + ' each' : '(included)' }}
                    </span>
                </label>
            </div>
        </message-list>
        <div class="flex-left no-wrap full-width" :class="custom_option.option && custom_option.option.help_text ? 'space-between' : ''">
            <div class="text-sm" v-if="custom_option.option && custom_option.option.help_text">{{ custom_option.option.help_text }}</div>
        </div>
    </fieldset>
</div>

<div class="input-group" v-for="custom_option in filteredOptions(${DisplayTypeEnum.DROPDOWN})">
    <label>{{ custom_option.option_set.name }}</label>
    <message-list field="option" :errors="custom_option.errors">
        <generic-dropdown 
            v-if="!readOnly" 
            :items="custom_option.option_set.options" 
            v-model:selected="custom_option.option" 
            :name-function="getOptionItemNameCallback(productService, custom_option.option_set)" 
            :disabled="!custom_option.option_set.optionEligible(productService.configured.width, productService.configured.height) || custom_option.isDisabled(productService.configured.material)">
        </generic-dropdown>
        <span v-if="readOnly">{{ getOptionItemName(productService, custom_option.option_set, custom_option.option) }}</span>
    </message-list>
</div>

<fieldset class="input-group" v-for="custom_option in filteredOptions(${DisplayTypeEnum.INCREMENTAL_OPTION})">
    <legend>{{ custom_option.option_set.name }}</legend>
    <message-list field="option" :errors="custom_option.errors">
        <div class="flex-left-center no-wrap full-width">
            <div class="toggle-group grow">
                <button type="button" @click="lastOption(custom_option)" :disabled="firstOptionSelected(custom_option)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16667 10H15.8333" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <span class="option-value">{{ custom_option.option.name }}</span>
                <button type="button" @click="nextOption(custom_option)" :disabled="lastOptionSelected(custom_option)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 4.16675V15.8334M4.16667 10.0001H15.8333" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <span class="no-shrink" v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_PERCENTAGE} && !hidePrice(custom_option)">
                {{ custom_option.option.percent > 0 ? '+' + $filters.shortNumber(custom_option.option.percent) + '%' : '(included)' }}
            </span>
            <span class="no-shrink" v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_TOTAL} && !hidePrice(custom_option)">
                {{ custom_option.option.amount > 0 ? '+' + $filters.currency(custom_option.option.amount, productService.configured.currency) : '(included)' }}
            </span>
            <span class="no-shrink" v-if="custom_option.option && custom_option.option.charge_option == ${TemplateOptionChargeOption.CHARGE_UNIT} && !hidePrice(custom_option)">
                {{ custom_option.option.amount > 0 ? '+' + $filters.currency(custom_option.option.display_value(productService.show_retail), productService.configured.currency) + ' each' : '(included)' }}
            </span>
        </div>
    </message-list>
</fieldset>
`;